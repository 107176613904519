<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">
          欢迎使用
        </h3>
      </div>
      <el-form-item prop="username">
        <span class="svg-container">
          用户名
        </span>
        <el-input
          v-model="loginForm.username"
          name="username"
          type="text"
          tabindex="1"
          auto-complete="on"
          placeholder="账户"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          密码
        </span>
        <el-input
          v-model="loginForm.password"
          :type="passwordType"
          name="password"
          auto-complete="on"
          tabindex="2"
          show-password
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>

      <el-button
        :loading="loading"
        type="primary"
        style="width:100%;margin-bottom:30px;"
        @click.native.prevent="handleLogin"
      >
        登录
      </el-button>
    </el-form>

    <div class="copyright">
      Copyright © 2020 版权所有
    </div>
  </div>
</template>

<script>
import imApi from "../utils/imapi";
import { setLocalStorage } from "../utils/local-storage";

export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },
      loginRules: {
        username: [
          { required: true, message: "管理员账户不允许为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "管理员密码不允许为空", trigger: "blur" },
          {
            validator: function(rule, value, callback) {
              if (value.length < 6) {
                callback(new Error("管理员密码长度应大于6"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      passwordType: "password",
      loading: false,
      imApi: new imApi()
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.imApi.setBaseUrl(this.$imAPI);
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid && !this.loading) {
          this.loading = true;
          this.imApi
            .Login(this.loginForm.username, this.loginForm.password)
            .then(data => {
              if (data.access_token) {
                setLocalStorage({
                  Authorization: data.access_token,
                  Expire: data.profile.expires_at
                });

                let uid = data.profile.sid;
                this.imApi
                  .GetUserInfo(uid)
                  .then(data => {
                    setLocalStorage({
                      UserInfo: JSON.stringify(data.userinfo),
                      SiteIds: data.serviceIds
                    });
                    this.loading = false;
                    this.$router.push({ path: this.redirect || "/" });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }
            })
            .catch(response => {
              this.$notify.error({
                title: "失败",
                message: response.data
              });
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 80%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 0 35px 160px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 60px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  .copyright {
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 20px;
    letter-spacing: 0.6px;
    a {
      font-weight: bold;
      border-bottom: 1px solid #fff;
      font-family: "PingFangSC-Semibold", sans-serif;
    }
  }
}
</style>
