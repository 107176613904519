import request from "@/utils/request";
import { getLocalStorage } from "@/utils/local-storage";

function imAPI() {
  this.baseUrl = "";
}

imAPI.prototype.setBaseUrl = function(url) {
  this.baseUrl = url;
};

imAPI.prototype.Login = function(usr, pwd) {
  return request({
    url: this.baseUrl + "Login",
    method: "get",
    params: {
      username: usr,
      password: pwd
    }
  });
};

imAPI.prototype.GetUserInfo = function(uid) {
  const { Authorization } = getLocalStorage("Authorization") || "";
  return request({
    url: this.baseUrl + "GetUserInfo",
    method: "get",
    params: {
      userid: uid
    },
    headers: {
      Authorization: "Bearer " + Authorization
    }
  });
};

imAPI.prototype.ThirdPartAuth = function(url, token) {
  return request({
    url: this.baseUrl + "ThirdPartAuth",
    method: "get",
    params: {
      authUrl: url,
      authToken: token
    }
  });
};

export default imAPI;
